import React from "react"
import styled from "@emotion/styled"
import * as theme from '../theme';

const StyledLabel = styled.span`
  background: ${props => props.background};
  color: ${props => props.foreground};
  position: relative;
  padding: ${props => props.orientation === "right"
    ? (props.small ? "0 30px 0 20px" : "0 35px 0 20px")
    : (props.small ? "0 20px 0 30px" : "0 20px 0 35px")
  };
  display: inline-flex;
  align-items: center;
  line-height: 2;
  font-size: ${props => (props.small ? "19px" : "24px")};
  font-family: ${theme.fonts.sans};
  height: ${props => (props.small ? "34px" : "40px")};

  clip-path: ${props => 
    props.orientation === "right"
      ? `polygon(100% 0%, ${props.small ? 'calc(100% - 10px)' : 'calc(100% - 15px)'} 50%, 100% 100%, 0px 100%, 0px 0px)`
      : `polygon(0px 0px, ${props.small ? '10px': '15px'} 50%, 0px 100%, 100% 100%, 100% 0px)`
  };
`

export const Label = ({
  children,
  background,
  foreground,
  small = false,
  orientation = "right",
}) => (
  <StyledLabel {...{ background, foreground, small, orientation }}>
    {children}
  </StyledLabel>
)

const StyledBlip = styled.span`
  background: ${props => props.background};
  color: ${props => props.foreground};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-family: ${theme.fonts.sans};
  ${props =>
    props.pointTo === "right" &&
    `
    height: 90px;
    width: 114px;
    padding-right: 24px;
    clip-path: url("#blip-path-right");
    /*
    clip-path: path(
      "M0 45c0 24.853 19.913 45 44.476 45C74.606 90 114 45 114 45S74.606 0 44.476 0C19.913 0 0 20.147 0 45"
    );
    */
  `}
  ${props =>
    props.pointTo === "left" &&
    `
    height: 90px;
    width: 114px;
    padding-left: 24px;
    clip-path: url("#blip-path-left");
    /*
    clip-path: path(
      "M114 45c0 24.853-19.913 45-44.476 45C39.394 90 0 45 0 45S39.394 0 69.524 0C94.087 0 114 20.147 114 45"
    );
    */
  `}
`

export const Blip = ({ children, background, foreground, pointTo }) => (
  <StyledBlip {...{ background, foreground, pointTo }}>{children}</StyledBlip>
)

export const BlipDefs = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: "absolute", opacity: 0, width: 0, height: 0, overflow: "hidden" }}
  >
    <defs>
      <clipPath id="blip-path-right">
        <path d="M0 45c0 24.853 19.913 45 44.476 45C74.606 90 114 45 114 45S74.606 0 44.476 0C19.913 0 0 20.147 0 45" />
      </clipPath>
      <clipPath id="blip-path-left">
        <path d="M114 45c0 24.853-19.913 45-44.476 45C39.394 90 0 45 0 45S39.394 0 69.524 0C94.087 0 114 20.147 114 45" />
      </clipPath>
    </defs>
  </svg>
)
